import { useRuntimeConfig } from '#app'

const { useCustomFetch } = useFetchBase()

const base = '/api/message'
const modules = '/customerCenter'
const path = base + modules

// request(REQ) Api  --------------------------------
const getCustomerListREQ = path + '/list/q' // 搜索客戶列表
const getCustomerREQ = path + '/get' // 獲取客戶信息
const getCustomerDetailREQ = path + '/get/details' // 獲取客戶詳情
const addCustomerREQ = path + '/add' // 添加客戶
const editCustomerREQ = path + '/update' // 更新客戶
const deleteCustomerREQ = path + '/del' // 刪除客戶
const deleteAllCustomerREQ = path + '/delAll' // 刪除全部客戶
const addCustomerTagsREQ = path + '/tags/add' // 添加客戶標籤
const addCustomerTagsBatchREQ = path + '/tags/batAdd' // 批量添加客戶標籤

// request(REQ) Function  ---------------------------

/**
 * 搜索客戶列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 資料
 */
export const getCustomerListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getCustomerListREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取客戶信息
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 資料
 */
export const getCustomerFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getCustomerREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取客戶詳情
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 資料
 */
export const getCustomerDetailsFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getCustomerDetailREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 添加客戶
 * @param {Object} formData 表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const addCustomerFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addCustomerREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 更新客戶
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const editCustomerFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editCustomerREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除客戶
 * @param {Object} params 參數 (ids = 客戶id)
 * @param {Object} callback 回調函數
 * @returns
 */
export const deleteCustomerFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(deleteCustomerREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 刪除所有客戶
 * @param {Object} callback 回調函數
 * @returns
 */
export const deleteAllCustomerFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + deleteAllCustomerREQ, {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 添加客戶標籤
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const addCustomerTagsFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(addCustomerTagsREQ, params), {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 批量添加客戶標籤
 * @param {Object} formData 表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const addCustomerTagsBatchFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addCustomerTagsBatchREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}
