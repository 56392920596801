import { defineStore } from 'pinia'
import { getCustomerListFn, addCustomerTagsFn } from '@/api/message/customerCenter'
import { getAllCustomerTagListFn } from '@/api/message/customerTag'
import { useConvInfo } from '@/stores/convInfo'

export const useCustomerCenterInfo = defineStore('customerCenterInfo', {
  state: () => ({
    progressLoading: false,
    customerList: [],
    listTotal: 0,
    customerTags: [], // 客戶標籤
    customerTagsMap: new Map(),
    convInfo: useConvInfo()
  }),
  getters: {
  },
  actions: {
    async getCustomerList (params) { // 獲取客戶列表
      this.progressLoading = true
      this.customerList = []

      const res = await getCustomerListFn(params)
      const data = res.data.value
      if (data.code === 200) {
        this.customerList = data.data.data
        this.listTotal = data.data.total
        this.progressLoading = false
      }
      return data
    },
    async getCustomerTags (params) { // 獲取客戶標籤
      const param = {
        pageNum: params.pageNum || 0,
        pageSize: params.pageSize || 100
      }
      const res = await getAllCustomerTagListFn(param)
      const data = res.data.value
      if (data.code === 200) {
        const customerTagsData = data.data.data

        customerTagsData.forEach((item) => {
          this.customerTagsMap.set(item.id, item)
        })

        this.customerTags = customerTagsData
      }
      return this.customerTags
    },
    compareArray (arr1, arr2) { // 比對兩個陣列是否相同
      return arr1.length === arr2.length && new Set(arr1).size === new Set(arr2).size &&
          [...new Set(arr1)].every(id => new Set(arr2).has(id))
    },
    async updateCustomerTagsFn (item, data, isAdd, type) {
      const postData = data.map(i => i.id)
      const customerId = item.customerId

      const params = {
        customerId
      }
      const res = await addCustomerTagsFn(params, postData)
      if (res.data.value.code === 200) {
        ElMessage.success({ message: isAdd ? '標籤更新成功' : '標籤刪除成功' })
      }

      if (type === 'conv') {
        this.convInfo.currentChatCustomer.customerTags = data
      }
    },
    addCustomerTags (tags, type) { // 新增客戶標籤
      const currentChatCustomer = this.convInfo.currentChatCustomer
      const oldTags = currentChatCustomer.customerTags ? currentChatCustomer.customerTags : []

      const itemTagIds = oldTags.map(tag => tag.id)
      const tagsIds = tags.map(tag => tag.id)

      if (this.compareArray(itemTagIds, tagsIds)) {
        return
      }
      this.updateCustomerTagsFn(currentChatCustomer, tags, true, type)
    },
    removeCustomerTag (tag, type) { // 刪除客戶標籤
      const item = this.convInfo.currentChatCustomer
      const updateTags = item.customerTags.filter(item => item.id !== tag.id)

      this.updateCustomerTagsFn(item, updateTags, false, type)
    }
  }
})
