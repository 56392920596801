import { defineStore } from 'pinia'
import { getDepartmentsFn, getDeptDetailFn } from '@/api/auth/departments'

export const useTeamInfo = defineStore('teamInfo', {
  state: () => ({
    deptList: [], // 部門列表
    deptDetMap: new Map(), // 部門map
    deptDetail: [] // 部門信息 (包含座席狀態)
  }),
  getters: {

  },
  actions: {
    async getDeptList (params) { // 獲取部門列表
      if (this.deptList.length > 0) { return this.deptList }

      const param = {
        pageNum: params.pageNum || 0,
        pageSize: params.pageSize || 20
      }
      const res = await getDepartmentsFn(param)
      const data = res.data.value
      if (data.code === 200) {
        this.deptList = data.data.data

        data.data.data.forEach((i) => {
          this.deptDetMap.set(i.id, i)
        })
      }
      return this.deptList
    },
    async getDeptDetail (params) { // 獲取部門詳細信息(包含座席狀態)
      const param = {
        pageNum: params.pageNum || 0,
        pageSize: params.pageSize || 100
      }

      const res = await getDeptDetailFn(param)
      const data = res.data.value
      if (data.code === 200) {
        this.deptDetail = data.data.data
      }
      return this.deptDetail
    }
  }
})
